@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* track*/
::-webkit-scrollbar-track{
  background: e0e0e0;
}

/* handle*/
::-webkit-scrollbar-thumb{
  background: #888;
}

/* handle onhover*/

::-webkit-scrollbar-track:hover {
  background: #555;
}
